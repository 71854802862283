:root {
  --primary-color: #3b5998;
  --secondary-color: #8b9dc3;
  --text-color: #333;
  --background-color: #f0f2f5;
}

body {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
